import React from 'react';
import {StatusColor} from "./StatusBadge";
import {cn} from "../utils/style";

function InvoiceBanner({status, backendMessage, className}) {
    if (status === 'pending') return null;
    const Messages = {
        not_found: "We couldn't find the invoice in our records.",
        unknown_error: "An unexpected error occurred. Please try again later.",
        completed: "Invoice paid successfully!",
        failed: "Payment failed. You can retry the payment.",
        refunded: "Your invoice has been refunded to your original method of payment.",
        canceled: "Your invoice was canceled as requested.",
        expired: "Your invoice has expired due to non-payment. Please consider renewing if you wish to continue with this transaction.",
        on_hold: "Your invoice is currently on hold while we verify the payment information. This may take a few moments."
    }
    let message = backendMessage || Messages[status] || '';
    let color = StatusColor[status] || '';
    return <div className={cn("p-2 rounded shadow-sm text-center border", color, className)}>
        {message}
    </div>
}

export default InvoiceBanner;