import React from 'react';
import InvoiceLayout from "../layouts/InvoiceLayout";
import {cn} from "../utils/style";
import InvoiceField from "../components/InvoiceField";
import MoneyHighlight from "../components/MoneyHighlight";
import {useTranslation} from "react-i18next";
import InvoiceBanner from "./InvoiceBanner";
import ButtonInternalLink from "./ButtonInternalLink";
import {apollo} from "../utils/apollo";
import {GET_TEMPORARY_RECEIPT_LINK, SEND_RECEIPT_TO_EMAIL} from "../utils/graphql";
import {useMutation} from "react-query";
import {toast} from "react-toastify";
import Language from "./Language";

function PaymentCallback({invoice, backendMessage}) {
    const {t} = useTranslation();
    const banner = <InvoiceBanner backendMessage={backendMessage} status={invoice?.status}/>;
    const total = <MoneyHighlight value={invoice?.amount}
                                  currency={invoice?.currency?.symbol}
                                  className={cn('!text-2xl')}/>;
    const isSatim = invoice?.gatewayCode === 'satim' || false;
    const completed = invoice?.status === 'completed' || false;
    return (<InvoiceLayout>
        <div className="flex flex-col items-center">
            <img
                src={completed ? "/images/payment-completed.png" : "/images/payment-failed.png"}
                className={cn("w-20 h-20")}
                alt={completed ? t('pay.payment_completed') : t('pay.payment_failed')}/>
            {}
            <h1 className="mt-4 mb-8 font-semibold">{completed ? "Payment Completed" : "Payment Failed"}</h1>
            <div className="w-full bg-white shadow p-4 rounded">
                <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 my-3">
                    <InvoiceField name="" value={banner} className="col-span-12"/>
                    <InvoiceField name={t("pay.status")} value={invoice?.status}
                                  className="col-span-12 sm:col-span-12"/>
                    <InvoiceField name={t("pay.invoice_id")} value={invoice?.paymentUuid}
                                  className="col-span-12 sm:col-span-12"/>
                    <InvoiceField name={t("pay.satim_transaction_id")} value={invoice?.gatewayReferenceNumber}
                                  hide={!completed}
                                  className="col-span-12 sm:col-span-12"/>
                    <InvoiceField name={t("pay.order_number")} value={invoice?.lastUsedIdentifier}
                                  className="col-span-12 sm:col-span-12"/>
                    <InvoiceField name={t("pay.auth_code")} value={invoice?.authCode}
                                  className="col-span-12 sm:col-span-12"
                                  hide={!completed}/>
                    <InvoiceField name={t("pay.paid_at")} value={invoice?.paidAt} className="col-span-12 sm:col-span-12"
                                  hide={!completed}/>
                    <InvoiceField name={t("pay.card_number")} value={invoice?.cardNumber}
                                  className="col-span-12 sm:col-span-12" hide={!completed}/>
                    {isSatim &&
                        <InvoiceField name={t("pay.le_mode_de_paiement")} value="Carte CIB/Edhahabia"
                                      className="col-span-4"/>}

                    <InvoiceField name={t('pay.total')} value={total} className="col-span-12"/>

                </div>
                <ButtonInternalLink href={`/pay/${invoice?.paymentUuid}`}>{t('pay.continue')}</ButtonInternalLink>

                <ReceiptSection invoice={invoice}/>

                {isSatim && <div className="mt-6 flex items-center text-gray-600 justify-between">
                    <div>{t('pay.satim_free_toll')}</div>
                    <img
                        className="shrink-0 w-20"
                        src="/images/3020.svg"
                        alt="SATIM 3020"/>
                </div>}
            </div>
            {/*<Language className="hidden sm:block mt-4"/>*/}
        </div>
    </InvoiceLayout>);
}


function ReceiptSection({invoice}) {
    const {t} = useTranslation();
    const getTemporaryReceiptLinkMutation = useMutation({
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: GET_TEMPORARY_RECEIPT_LINK, variables: {
                    input: {
                        uuid: invoice.paymentUuid,
                        action: variables?.action || "download",
                    }
                },
            });
            return data?.getTemporaryReceiptLink;
        },
        onSuccess: async (data) => {
            if (!data) return;
            window.open(data, '_blank');
        }
    })

    const sendReceiptToEmailMutation = useMutation({
        mutationFn: async (variables) => {
            const {data} = await apollo.mutate({
                mutation: SEND_RECEIPT_TO_EMAIL, variables: {
                    input: {
                        uuid: invoice.paymentUuid,
                    }
                },
            });
            return data?.sendReceiptToEmail;
        },
        onSuccess: async (data) => {
            if (data) {
                toast.success("Receipt has been sent!");
            } else {
                toast.error("Something went wrong!");
            }
        }
    })

    if (invoice?.status !== 'completed') return;

    return <div className="relative flex items-center w-full justify-center gap-2 mt-4">
        {/*<div className="absolute top-0 left-0 w-full h-full bg-white/60"></div>*/}
        <div onClick={() => getTemporaryReceiptLinkMutation.mutate({action: 'download'})}
             className="px-2 text-center bg-gray-100 select-none cursor-pointer w-32 flex flex-col items-center py-1.5 rounded">
            <svg className="w-7 h-7 ltr:mr-2 rtl:ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M12 10v6m0 0l-3-3m3 3l3-3m2 8H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"></path>
            </svg>
            {t('pay.download')}
        </div>

        <div onClick={() => getTemporaryReceiptLinkMutation.mutate({action: 'inline'})}
             className="px-2 text-center bg-gray-100 select-none cursor-pointer w-32 flex flex-col items-center py-1.5 rounded">
            <svg className="w-7 h-7 ltr:mr-2 rtl:ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"></path>
            </svg>
            {t('pay.print')}
        </div>

        <div onClick={() => sendReceiptToEmailMutation.mutate()}
             className="px-2 text-center bg-gray-100 select-none cursor-pointer w-32 flex flex-col items-center py-1.5 rounded">
            <svg className="w-7 h-7 ltr:mr-2 rtl:ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                 xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                      d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"></path>
            </svg>
            {t('pay.send_to_email')}
        </div>

    </div>
}


export default PaymentCallback;