import React, {Fragment} from 'react'
import {Popover, Transition} from '@headlessui/react'
import {ChevronDownIcon} from '@heroicons/react/20/solid'
import {useTranslation} from "react-i18next";
import {clsx} from "clsx";

const solutions = [
    {name: 'English', lang: 'en'},
    {name: 'French', lang: 'fr'},
    {name: 'Arabic', lang: 'ar'},
]

function Language({className}) {
    const {t, i18n} = useTranslation();

    return (
        <Popover className={clsx(className, "relative")}>
            <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900">
                <span>{t('_.language')}</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true"/>
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                    {({close}) => (<div
                        className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                        {solutions.map((item) => (
                            <button key={item.name} type="button" onClick={() => {
                                console.log(item.lang)
                                i18n.changeLanguage(item.lang)
                                close()
                            }} className="block p-2 hover:text--blue-500">
                                {item.name}
                            </button>
                        ))}
                    </div>)}
                </Popover.Panel>
            </Transition>
        </Popover>
    )
}

export default Language;