import React from 'react';
import {cn} from "../utils/style";

function Button({type, children, onClick, secondary, className, disabled}) {
	return (<button
		onClick={e => {
			e.preventDefault();
			onClick(e);
		}}
		type={type || 'button'}
		disabled={disabled || false}
		className={cn(
			"block rounded-md text-sm px-3 py-2 text-center font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500",
			{
				'bg-gradient-to-br from-red-500 to-orange-500 hover:from-red-400 hover:to-orange-400': secondary,
				'bg-gradient-to-br from-green-500 to-blue-500 hover:from-green-400 hover:to-blue-400': !secondary
			},
			className,
			{
				'!opacity-40': disabled
			}
		)}
	>
		{children}
	</button>);
}

export default Button;