export const generateRandomString = (length = 10) => {
	let result = '';
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

export const formatNumberFloat = (num = 0) => {
	if (typeof num === 'string') num = Number(num)
	return num?.toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2});
}

export const formatNumberInt = (num = 0) => {
	if (typeof num === 'string') num = Number(num)
	return num?.toLocaleString('en-US', {minimumFractionDigits: 0, maximumFractionDigits: 0});
}

// let intNum = 1234567;
// let floatNum = 123456.78;
// let stringNum = '123456789.00';
//
// console.log(formatNumber(intNum)); // 1,234,567.00
// console.log(formatNumber(floatNum)); // 123,456.78
// console.log(formatNumber(Number(stringNum))); // 123,456,789.00