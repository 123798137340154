import React from 'react';

function Logo() {
	return (
		<img
			className="h-20 w-auto"
			src="/logo.png"
			alt="Payd"
		/>
	);
}

export default Logo;